import React from "react";
import { Stack } from "@mui/material";
import Header from "./header";
import Hero from "./hero";
import Features from "./features";
import Faq from "./faq";
import ContactUs from "./contact";
import Footer from "./footer";
import GoToTop from "./fab";
import SocialProofSection from "../components/SocialProofSection";
import UseCase from "./usecase";
import Pricing from "./pricing";
import GetStart from "./getstart";
import Tutorials from "./Tutorials";
import Join from "../components/Join";
import Question from "./question";

export default function LandingPage() {
  return (
    <Stack>
      <Header />
      <Hero />
      <Features />
      <UseCase />
      <Pricing />
      <GetStart />
      <Question />
      <Tutorials />
      <ContactUs />
      <Join />
      <ContactUs />
      <Footer />
      <GoToTop />
    </Stack>
  );
}
