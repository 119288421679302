import { ArrowRight } from "@mui/icons-material";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";
import { ARROWRIGHT } from "../assets/icons";

export default function TutorilCards(props: {
  onClick: any;
  heading: string;
  about: string;
}) {
  // props: {
  //   icon: ReactElement;
  //
  //
  //   style?: CSSProperties;
  // }
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      spacing={"20px"}
      sx={{
        width: desktop ? "500px" : "300px",
        // height: desktop ? "260px" : "200px",
        backgroundColor: "#333333",
        borderRadius: "8px",
        padding: desktop ? "10px 24px" : "10px 24px",
        alignItems: "start",
        lineHeight: "24px",
        // ":hover": {
        //   background: "transparent",
        //   backdropFilter: "blur(4px)",
        // },
      }}
    >
      {/* <Typography
        sx={{
          width: "48px",
          height: "48px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "28px",
          // background: "#FEF7C3",
        }}
      >
        {props.icon}
      </Typography> */}
      <Typography
        sx={{
          color: "#7258e8",
          fontSize: desktop ? "28px" : "18px",
          fontStyle: "normal",
          fontWeight: desktop ? "600" : "400",
          textAlign: "start",
        }}
      >
        {/* Getting Started */}
        {props.heading}
      </Typography>
      <Typography
        sx={{
          textAlign: "start",
          color: "#b4b4b4b4",
          fontSize: desktop ? "16px" : "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {/* Join our waitlist seamlessly with our user-friendly signup process.Our
        detailed walkthrough will guide you through every step, making it quick
        and hassle-free. */}
        {props.about}
      </Typography>{" "}
      <Button
        sx={{
          color: "#6772E5",
          fontSize: "16px",
          fontWeight: "600",
          ":hover": {
            background: "none",
            color: "#7258e8",
          },
        }}
        onClick={props.onClick}
      >
        Read More <ARROWRIGHT />
      </Button>
    </Stack>
  );
}
