import React, { useEffect, useState } from "react";
import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";

import Header from "../pages/header";
import Signup from "../assets/icons/signupimage.png";
import Signupsucces from "../assets/icons/signupsuccesimage.png";
import Waitlist from "../assets/icons/waitlistimage.png";
import verify from "../assets/icons/verifyimage.png";
import Footer from "../pages/footer";
import image1 from "./images/ad1.png";
import image2 from "./images/ad2.png";
import image3 from "./images/ad3.png";
import image4 from "./images/ad4.png";
import image5 from "./images/ad5.png";

export default function Announcements() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <>
      <Header />
      <Stack
        //   id="hero"
        alignItems={"center"}
        //   justifyContent={desktop ? "center" : "flex-start"}
        sx={{
          minHeight: desktop ? "100vh" : undefined,
          overflow: "hidden",
          //   background: "#383838",
          background: "#ffff",
          paddingBottom: "30px",
        }}
        spacing={desktop ? "46px" : "12px"}
      >
        <Stack spacing={desktop ? "46px" : "12px"} alignItems={"center"}>
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              color: "#7258e8",
              fontSize: desktop ? "48px" : "30px",
              textAlign: "center",
              paddingTop: "30px",
              width: desktop ? "60%" : "90%",
            }}
          >
            Sending Announcements from Announcely Dashboard
          </Typography>
          <Divider sx={{}} />
        </Stack>
        <Stack
          sx={{
            alignItems: "flex-start",
            justifyContent: "center",
            width: desktop ? "60%" : "90%",
            color: "#333333",
            paddingBottom: "50px",
          }}
          spacing={"20px"}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Introduction{" "}
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            <p>
              Whether you wish to send an instant announcement or schedule one
              for later, Announcely has you covered. Our intuitive dashboard
              enables you to send announcements to various platforms and
              recipients from a single location. You can effortlessly send an
              announcement to a particular Discord channel (which can be
              forwarded using linked chats to both WhatsApp And Telegram
              simultaneously).
            </p>
            <p>
              Sending announcements is now easier than ever. Just follow these
              steps:
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 1: Log in to your Announcely dashboard
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            {/* link */}
            1. Visit Announcely Portal and log in to your account.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 2: Click on the “Announcements” button{" "}
          </Typography>
          <Typography>
            <p>
              Locate the “Create an Announcement” button. You can find this
              button on the dashboard within the Quick Actions section, and it’s
              also available in the “Announcements” section on the sidebar.
            </p>
            <img
              src={image1}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3: Select your Audience{" "}
          </Typography>
          <Typography>
            <p>Select your desired discord channel from the dropdown menu:</p>
            <img
              src={image2}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 4: Write your message
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            <p>
              Enter the text message that you would like to send. If you wish to
              include any attachments, simply add it in the attachment field.
            </p>
            <img
              src={image3}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>

          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 4: Write your message
          </Typography>

          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 5: Set when to send the Announcement
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            <p>
              If you intend to schedule the announcement to be sent at a later
              time automatically, select “Schedule” and then choose the date and
              time at which you would like the announcement to be sent.
            </p>
            <p>
              If you prefer to send the announcement instantly, choose the “Now”
              option instead.
            </p>
            <img
              src={image4}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 6: Send the Announcement{" "}
          </Typography>
          <Typography>
            <p>
              Just click “Send,” and your announcement will be scheduled to be
              sent, whether it’s for immediate delivery or at a later time.
            </p>
            <img
              src={image5}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
