import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeManager from "./middlewares/ThemeManager";
import PageSplashScreen from "./components/SplashScreen";
import "./index.css";
import { RecoilRoot } from "recoil";
import LandingPage from "./pages";
import GettingStarted from "./Howto/getStart";
import LinkingDiscord from "./Howto/DiscordChannal";
import Onboarding from "./Howto/Onboarding";
import Announcements from "./Howto/Announcements";
// import "./assets/icons/HKGrotesk/"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    {/* The theme provider is added at the root level  */}

    <ThemeManager>
      {/* <ModalProvider> */}
      {/* <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            > */}
      <PageSplashScreen>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/*" element={<LandingPage />} />
            <Route path="/GettingStarted" element={<GettingStarted />} />
            <Route path="/LinkingDiscord" element={<LinkingDiscord />} />
            <Route path="/Onboarding" element={<Onboarding />} />
            <Route path="/Announcements" element={<Announcements />} />
          </Routes>
        </BrowserRouter>
      </PageSplashScreen>
      {/* </motion.div> */}

      {/* </ModalProvider> */}
    </ThemeManager>
  </RecoilRoot>
);
