import React from "react";
import { useState, useEffect } from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";

export default function PageSplashScreen({ children }: any) {
  const [initialized, setInitialized] = useState(false);
  const desktop = useMediaQuery("(min-width: 700px)");

  useEffect(() => {
    setTimeout(() => setInitialized(true), 1000);
  }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100%",
        alignItems: "center",
        background: "#0C111D",
      }}
    >
      <Typography
        sx={{
          position: "fixed",
          top: desktop ? "64px" : undefined,
          left: desktop ? "80px" : undefined,
          pointerEvents: "none",
          height: "100px",
          objectFit: "contain",
          width: desktop ? undefined : "300px",
        }}
      >
        {/* <LOGOHEADER /> */}
      </Typography>
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Player
          autoplay
          loop
          src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
          style={{ height: desktop ? "180px" : "100px" }}
        />
        {/* <Typography
          className="animated-text"
          fontSize={"24px"}
          fontWeight={"600"}
          sx={{ color: "#F5F5F6", position: "absolute", bottom: "100px" }}
        >
          Loading application, please wait...
        </Typography> */}
      </Stack>
    </Stack>
  );
}
