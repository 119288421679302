import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";

export default function BotDevelopmentCard(props: {
  icon: ReactElement;
  heading: string;
  about: string;
  style?: CSSProperties;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      spacing={"10px"}
      sx={{
        width: desktop ? "320px" : "300px",
        // height: desktop ? "292px" : "200px",
        backgroundColor: "none",
        alignItems: "center",
        // padding: "0 20px",
        // color: "#EDEDED",
        // lineHeight: "24px",
        ":hover": {
          transitionProperty: "transform",
          transitionDuration: "3s",
          transform: "scale(1.1)",
          // paddingTop: desktop ? "20px" : "2px",
        },
      }}
    >
      <Typography
        sx={{
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "28px",
          // background: "#FEF7C3",
        }}
      >
        {props.icon}
      </Typography>
      <Typography
        sx={{
          width: "80%",
          color: "#fff",
          fontSize: desktop ? "23px" : "23px",
          fontStyle: "normal",
          fontWeight: desktop ? "600" : "400",
          textAlign: "center",
        }}
      >
        {props.heading}
      </Typography>
      <Typography
        sx={{
          width: "80%",
          textAlign: "center",
          color: "#ffffffcc",
          fontSize: desktop ? "16px" : "16px",
          fontStyle: "normal",
          alignItems: "center",
          fontWeight: "400",
          textAlignLast: "center",
          //   fontFamily: "HK Grotesk",
        }}
      >
        {props.about}
      </Typography>
    </Stack>
  );
}
