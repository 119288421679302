import { useState } from "react";
import React from "react";

import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import PFMInput from "../components/PFMInput";
import Stripe from "../assets/icons/Poweredbystripe.png";
import { LOGO_FOOTER } from "../assets";
export default function Footer() {
  const desktop = useMediaQuery("(min-width: 720px)");
  const [busy, setBusy] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <Stack
      id="footer"
      sx={{
        background: "#48F1",
        pt: "48px",
        pb: "24px",
      }}
      spacing="48px"
    >
      <Stack sx={{ pt: "48px", pb: "24px" }} spacing="48px">
        {/* The logo, its menu, and subscription container  */}
        <Stack
          direction={desktop ? "row" : "column"}
          justifyContent={desktop ? "space-around" : "center"}
          sx={{ flexWrap: "wrap" }}
          alignItems={"center"}
          columnGap={"32px"}
          rowGap={"32px"}
        >
          {/* Logo and Menu  */}
          <Stack
            alignItems={desktop ? "flex-start" : "center"}
            spacing={"10px"}
          >
            <img
              src={LOGO_FOOTER}
              alt="Announcely Logo"
              style={{ height: "128px", objectFit: "contain" }}
            />
            <Typography>
              Reach your community. Everywhere. Instantly.
            </Typography>
            <Typography sx={{ paddingTop: "20px" }}>
              <img src={Stripe} alt="" />
            </Typography>
          </Stack>
          {/* Subscribe  */}
          <Stack
            columnGap={"18px"}
            direction={"row"}
            rowGap={"18px"}
            flexWrap={desktop ? undefined : "wrap"}
            alignItems={desktop ? undefined : "center"}
            justifyContent={desktop ? "flex-end" : "center"}
            sx={{ maxWidth: "80%" }}
          >
            <PFMInput
              text={email}
              onUpdate={(t) => setEmail(t)}
              disabled={busy}
              label="Stay up to date with our new services"
              placeholder="Enter your email address..."
            />
            <Button
              disabled={busy}
              sx={{
                px: "44px",
                height: "44px",
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Subscribe
            </Button>
          </Stack>
        </Stack>
        {/* <Stack
          
          rowGap={"18px"}
        >
          <Typography textAlign={"center"}>
            Designed & Developed with 💖 by{" "}
            <Typography
              color={"primary"}
              textAlign={"center"}
              component={"a"}
              sx={{ textDecoration: "none" }}
              href="https://zexware.com"
            >
              Zexware (Private) Limited
            </Typography>
          </Typography>
        </Stack> */}
      </Stack>
      <Stack alignItems={"center"} justifyContent={"center"}>
        <Typography
          sx={{
            fontSize: desktop ? "16px" : "14px",
            // padding: desktop ? "" : "0px 15px",
            textAlign: "center",
          }}
        >
          Copyright ©️ 2023 - Announcely, Ltd. | Privacy Policy | Terms &
          Conditions | Cookie Policy | Refund Policy
        </Typography>
      </Stack>
    </Stack>
  );
}
