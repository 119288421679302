import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import DOT_BG from "../assets/icons/dotbackground.png";
import { motion } from "framer-motion";
import {
  AD,
  ANNOUNCEMENT,
  CHARTHISTOGRAM,
  GIFT,
  LIFE,
  STAR,
  TOURNAMENT,
  TWITCH,
  TWITTER,
} from "../assets/icons";
import UseCasesCard from "../components/UseCaseCard";
export default function UseCase() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="features"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        bgcolor: "#050505",
        py: "48px",
      }}
    >
      <Stack>
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          <Typography
            sx={{
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: "35px",
              fontWeight: 600,
              lineHeight: "30px",
            }}
          >
            Use Cases{" "}
          </Typography>
        </motion.div>
        <img
          src={DOT_BG}
          alt=""
          style={{
            position: "absolute",
            right: 100,
            marginTop: "0px",
            pointerEvents: "none",
          }}
        />
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        flexWrap={"wrap"}
      >
        <UseCasesCard
          icon={<ANNOUNCEMENT />}
          heading="ANNOUNCEMENTS"
          about="Announcely ensures your important announcements reach every member, no matter their preferred platform, fostering a truly unified community experience."
        />

        <UseCasesCard
          icon={<STAR />}
          heading="NFT MINTS"
          about="Keep your community in the loop about NFT mints in real-time. Announcely synchronizes announcements across Discord, WhatsApp, and Telegram."
        />

        <UseCasesCard
          icon={<TWITCH />}
          heading="TWITCH STREAMS"
          about="Broadcast your Twitch stream announcements seamlessly to Discord, WhatsApp, and Telegram, ensuring a full house for every session."
        />
      </Stack>{" "}
      <Stack
        sx={{ mt: "24px" }}
        justifyContent={"center"}
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        flexWrap={"wrap"}
      >
        <UseCasesCard
          icon={<GIFT />}
          heading="GIVEAWAYS"
          about="Coordinate giveaways seamlessly by broadcasting instructions and updates across Discord, WhatsApp, and Telegram simultaneously with Announcely."
        />
        <UseCasesCard
          icon={<TWITTER />}
          heading="TWITTER SPACES"
          about="Extend the reach of your Twitter Spaces by syncing announcements across Discord, WhatsApp, and Telegram with Announcely, amplifying participation and engagement."
        />
        <UseCasesCard
          icon={<AD />}
          heading="PROMOTIONS"
          about="Drive more visibility to your promotions. Announcely ensures that your promotional messages resonate across all platforms, maximizing your reach."
        />
      </Stack>{" "}
      <Stack
        sx={{ mt: "24px" }}
        justifyContent={"center"}
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        flexWrap={"wrap"}
      >
        <UseCasesCard
          icon={<TOURNAMENT />}
          heading="TOURNAMENTS"
          about="Elevate your tournament experience by broadcasting match details, updates, and results across Discord, WhatsApp, and Telegram simultaneously.         "
        />
        <UseCasesCard
          icon={<LIFE />}
          heading="LIFE EVENTS"
          about="Share and celebrate life's moments effortlessly. Announcely makes sure your community is informed and engaged during birthdays, milestones, and more."
        />
        <UseCasesCard
          icon={<CHARTHISTOGRAM />}
          heading="TRADE ALERTS"
          about="Stay ahead in the trading game! Announcely enables instant notifications across platforms, ensuring your community never misses a crucial trade alert."
        />
      </Stack>
      <Stack
        alignItems={"center"}
        style={{ maxWidth: desktop ? "40%" : "90%" }}
      >
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          {/* <Typography
            fontSize={desktop ? 20 : 14}
            fontWeight={400}
            style={{ textAlign: "center", color: "black" }}
          >
            Explore additional videos and tutorials on our YouTube channel for
            an in-depth look into Cashifybot's functionalities.
          </Typography> */}
        </motion.div>
        {/* <Button sx={{ borderRadius: "25px" }}>See Playlist</Button> */}
      </Stack>
    </Stack>
  );
}
