import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";

export default function UseCasesCard(props: {
  icon: ReactElement;
  heading: string;
  about: string;
  style?: CSSProperties;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      sx={{
        width: desktop ? "338px" : "300px",
        height: desktop ? "300px" : "300px",
        backgroundColor: "#0F192700",
        borderRadius: "8px",
        justifyContent: "center",
        // padding: desktop ? "18px " : "10px 24px",

        alignItems: "center",
        // color: "#EDEDED",
        border: "1px solid rgba(255, 255, 255, 0.15)",
        backdropFilter: "blur(2px)",
        ":hover": {
          background: "#0F192780",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <Stack
        spacing={"20px"}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            width: "38px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "28px",
            // background: "#FEF7C3",
          }}
        >
          {props.icon}
        </Typography>
        <Typography
          sx={{
            color: "#6165ec",
            //   fontFamily: " HK Grotesk",
            fontSize: desktop ? "22px" : "18px",
            fontStyle: "normal",
            fontWeight: desktop ? "600" : "600",
            textAlign: "center",
            lineHeight: "24px",
          }}
        >
          {props.heading}
        </Typography>
        <Typography
          sx={{
            width: "80%",
            textAlign: "center",
            color: "#fff",
            fontSize: desktop ? "16px" : "16px",
            fontStyle: "normal",
            fontWeight: "400",
            //   fontFamily: "HK Grotesk",
          }}
        >
          {props.about}
        </Typography>
      </Stack>
    </Stack>
  );
}
