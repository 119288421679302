import vector from "./vector.svg";
import spalshscreenlogo from "./spalshscreenlogo.svg";
import chatgptbot from "./ChatGPTBots.svg";
import mirrorbot from "./MirrorBots.svg";
import moderationbot from "./ModerationBots.svg";
import paymentbot from "./PaymentBots.svg";
import shopping from "./Shopping.svg";
import link from "./link-alt.svg";
import telegram from "./telegram.svg";
import linkpricing from "./link-altpricing.svg";
import stats from "./stats.svg";
import volume from "./volume.svg";
import whatsapp from "./whatsapp.svg";
import infinity from "./infinity.svg";
import arrowright from "./arrow-small-right.svg";
import infinityfuture from "./infintyfuture.svg";
import comment from "./comment.svg";
import dotbackground from "./Backgroundimage.svg";
import ellipse from "./Ellipsebackground.svg";
import announcement from "./notification-2-line.svg";
import star from "./star-fill.svg";
import twitch from "./twitch-line.svg";
import gift from "./gift-line.svg";
import twitter from "./twitter-line.svg";
import ad from "./advertisement-line.svg";
import tournament from "./gamepad-line.svg";
import life from "./heart-fill.svg";
import trade from "./chart-histogram.svg";

export const VECTOR = vector;
export const SPLASHSCREENLOGO = spalshscreenlogo;
export const CHATGPTBOT = chatgptbot;
export const MIRRORBOTS = mirrorbot;
export const MODERATIONBOTS = moderationbot;
export const PAYMENTBOTS = paymentbot;
export const SHOPPING = shopping;
export const LINK = link;
export const TELEGRAMPRICING = telegram;
export const LINKALT = linkpricing;
export const STATS = stats;
export const VOLUME = volume;
export const WHATSAPP = whatsapp;
export const INFINITY = infinity;
export const ARROWRIGHT = arrowright;
export const INFINITYFUTURE = infinityfuture;
export const COMMENT = comment;
export const DOTBACKGROUNDIMAGE = dotbackground;
export const ELLIPSE = ellipse;
export const ANNOUNCEMENT = announcement;
export const STAR = star;
export const TWITCH = twitch;
export const GIFT = gift;
export const TWITTER = twitter;
export const AD = ad;
export const TOURNAMENT = tournament;
export const LIFE = life;
export const CHARTHISTOGRAM = trade;
