import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";
import { TELEGRAMPRICING } from "../assets/icons";
import { WidthFull } from "@mui/icons-material";

export default function PricingCardInfo(props: {
  title?: string;
  icon: ReactElement;
  description?: string;
  headingsx?: CSSProperties;
  backgroundcolor?: CSSProperties;
  //   value?: any;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      direction={"row"}
      spacing={desktop ? "30px" : "15px"}
      sx={{ alignItems: "flex-start" }}
    >
      {/* Icon  */}
      <Typography
        sx={{
          ...(props.backgroundcolor || {}),
          height: desktop ? "56px" : "42px",
          width: desktop ? "56px" : "42px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "100% ",
        }}
      >
        {props.icon}
      </Typography>

      <Stack spacing={"8px"}>
        <Typography
          fontSize={desktop ? "16px" : "14px"}
          fontWeight={600}
          sx={{ ...(props.headingsx || {}) }}
        >
          {props.title}
        </Typography>
        {/* Text  */}
        <Typography
          fontWeight={400}
          fontSize={"12px"}
          sx={{ width: desktop ? "316px" : "200px", color: "#a2a2a2" }}
        >
          {props.description}
        </Typography>
        {/* Email  */}
        {/* <Typography sx={{ mt: "16px" }} fontWeight={600} fontSize={16}>
          {props.value}
        </Typography> */}
      </Stack>
    </Stack>
  );
}
