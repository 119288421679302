import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import PricingCard from "../components/PricingCard";
import { motion } from "framer-motion";

export default function Pricing() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="Pricing"
      spacing={"32px"}
      alignItems={"center"}
      sx={{
        bgcolor: "#2a2a2a",
      }}
    >
      <Stack
        spacing={"15px"}
        sx={{ alignItems: "center", justifyContent: "center", padding: "10px" }}
      >
        <Typography
          sx={{
            color: "#fff",
            padding: "20px 0px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            fontSize: desktop ? "52px" : "46px",
            fontWeight: 600,
            lineHeight: "30px",
          }}
        >
          Pricing
        </Typography>
        {/*  */}
        <Typography
          fontSize={desktop ? "35px" : "30px"}
          textAlign={"center"}
          sx={{
            color: "#fff",
            fontWeight: "400",
            textAlign: "center",
            width: desktop ? "60%" : "80%",
          }}
        >
          Choose a price plan that works for you.
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            color: "#adadad",
            fontSize: desktop ? "16px" : "14px",
            fontWeight: "400",
            textAlign: "center",
            width: desktop ? "100%" : "90%",
          }}
        >
          With every pricing plan, you will receive a free 7-day trial to test
          all the features of Announcely. You can cancel anytime before being
          charged for the subscription.
        </Typography>
        <Typography
          fontSize={desktop ? "16px" : "14px"}
          textAlign={"center"}
          sx={{
            color: "#adadad",
            fontWeight: "400",
            textAlign: "center",
            width: desktop ? "60%" : "90%",
          }}
        >
          Note: Prices are in USD.
        </Typography>{" "}
        {/* */}
      </Stack>
      <Stack
        justifyContent={"center"}
        direction={"row"}
        rowGap={"60px"}
        columnGap={"60px"}
        flexWrap={"wrap"}
      >
        <motion.div
          whileInView={{ translateX: 0 }}
          initial={{ translateX: -300 }}
          viewport={{ once: true }}
          transition={{ type: "spring", stiffness: 40, delay: 0 }}
        >
          <PricingCard
            heading="Telegram"
            sx={{ background: "" }}
            sa={{}}
            monthlyprice="$19.9"
            yraelyprice="$239.9"
          />
        </motion.div>
        <motion.div
          viewport={{ once: true }}
          whileInView={{ translateX: 0 }}
          initial={{ translateX: 300 }}
          transition={{ type: "spring", stiffness: 40, delay: 0 }}
        >
          <PricingCard
            heading="Premium 👑 "
            sx={{ background: "#7546b3" }}
            sa={{ background: "#3a2860" }}
            monthlyprice="$24.9"
            yraelyprice="$299.9"
          />{" "}
        </motion.div>
      </Stack>
    </Stack>
  );
}
