import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import YouTubePlayer from "react-player/youtube";
import { motion } from "framer-motion";
import UseCasesCard from "../components/UseCaseCard";
import TutorilCards from "../components/TutorialCard";

export default function Tutorials() {
  const desktop = useMediaQuery("(min-width: 700px)");
  function getstart() {
    var url = "./GettingStarted";
    window.location.assign(url);
  }
  function LinkingDiscord() {
    var url = "./LinkingDiscord";
    window.location.assign(url);
  }
  function Onboarding() {
    var url = "./Onboarding";
    window.location.assign(url);
  }
  function SendingAnnouncements() {
    var url = "./Announcements";
    window.location.assign(url);
  }
  return (
    <Stack
      id="demo"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        bgcolor: "#383838",
        py: "48px",
      }}
    >
      <Stack>
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          <Typography
            sx={{
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: "32px",
              fontWeight: 600,
              lineHeight: "30px",
            }}
          >
            Tutorials & How To
          </Typography>
        </motion.div>
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        ></motion.div>
      </Stack>
      <Paper
        style={{
          borderRadius: "8px",
          //   overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: desktop ? undefined : "80%",
        }}
      >
        <YouTubePlayer
          url={"https://youtu.be/HazsNvN-cNk"}
          width={desktop ? undefined : "auto"}
          height={desktop ? undefined : "auto"}
        />
      </Paper>
      <Stack
        alignItems={"center"}
        style={{ maxWidth: desktop ? "40%" : "90%" }}
      >
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          {/* <Typography
            fontSize={desktop ? 20 : 14}
            fontWeight={400}
            style={{ textAlign: "center" }}
          >
            Explore additional videos and tutorials on our YouTube channel for
            an in-depth look into Cashifybot's functionalities.
          </Typography> */}
        </motion.div>
        {/* <Button sx={{ borderRadius: "25px" }}>See Playlist</Button> */}
      </Stack>

      <Stack
        // direction={"row"}
        // columnGap={"50px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"50px"}
        columnGap={"50px"}
        flexWrap={"wrap"}
        // justifyContent={"space-between"}
      >
        <TutorilCards
          heading="Getting Started"
          about="Join our waitlist seamlessly with our user-friendly signup process. Our detailed walkthrough will guide you through every step, making it quick and hassle-free."
          onClick={getstart}
        />
        <TutorilCards
          heading="Onboarding"
          about="The onboarding process is designed to help you effortlessly set up your Announcely dashboard and bots for use with your Discord server."
          onClick={Onboarding}
        />
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"50px"}
        columnGap={"50px"}
        flexWrap={"wrap"}
        // justifyContent={"space-between"}
      >
        <TutorilCards
          heading="Linking Discord Channels"
          about="This article demonstrates the process of linking a Discord channel to WhatsApp and/or Telegram using Announcely."
          onClick={LinkingDiscord}
        />
        <TutorilCards
          heading="Sending Announcements"
          about="This short tutorial will guide you through the process of sending Announcements from dashboard. Use this if you want to send announcements from dashboard."
          onClick={SendingAnnouncements}
        />
      </Stack>
    </Stack>
  );
}
