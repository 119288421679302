import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";
import PricingCardInfo from "./PricingCardInfo";
import {
  INFINITY,
  LINKALT,
  STATS,
  TELEGRAMPRICING,
  VOLUME,
  WHATSAPP,
} from "../assets/icons";

export default function PricingCard(props: {
  heading?: string;
  monthlyprice?: string;
  yraelyprice?: string;
  sx: CSSProperties;
  sa: CSSProperties;
  // icon: ReactElement;
  // description?: string;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack sx={{ width: "auto", background: "#222222" }}>
      <Stack spacing={"30px"} sx={{ ...(props.sx || {}) }}>
        <Typography
          sx={{
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            fontSize: desktop ? "22px" : "22px",
            fontWeight: 600,
            lineHeight: "30px",
            paddingTop: "10px",
            textAlign: "center",
          }}
        >
          {props.heading}
        </Typography>
        <Stack justifyContent={"space-around"} direction={"row"} sx={{}}>
          {" "}
          <Stack spacing={"10px"}>
            <Typography
              sx={{
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: desktop ? "25px" : "22px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Monthly
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: desktop ? "35px" : "28px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              {props.monthlyprice}
              {/* $19.99 */}
            </Typography>
            <Typography
              sx={{
                color: "#a2a2a2",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "30px",
              }}
            >
              per month
            </Typography>
          </Stack>
          <Stack spacing={"10px"}>
            <Typography
              sx={{
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: desktop ? "25px" : "22px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Yearly
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: desktop ? "35px" : "28px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              {props.yraelyprice}
              {/* $19.99 */}
            </Typography>
            <Typography
              sx={{
                color: "#a2a2a2",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "30px",
              }}
            >
              per year
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ ...(props.sa || {}) }}>
        <Stack
          sx={{ padding: desktop ? "50px 30px 20px 30px" : "40px 8px" }}
          spacing={desktop ? "4px" : 0}
          rowGap={"24px"}
        >
          <PricingCardInfo
            icon={<TELEGRAMPRICING />}
            title="Telegram"
            backgroundcolor={{ background: "#229ed9" }}
            headingsx={{ color: "#229ed9" }}
            description="Connect your Discord channels with your Telegram group, through official Telegram bot accounts."
          />
          <PricingCardInfo
            icon={<INFINITY />}
            backgroundcolor={{ background: "#5d5ed9" }}
            title="Unlimited Announcements"
            description="There is no limit on the amount of announcements that can be sent."
            headingsx={{ color: "#5d5ed9" }}
          />
          <PricingCardInfo
            icon={<LINKALT />}
            backgroundcolor={{ background: "#8655ff" }}
            title="Linked Channels"
            description="Mirror messages from your Discord channels to Telegram. Supports images, audio, video, and gifs.  "
            headingsx={{ color: "#8655ff" }}
          />
          <PricingCardInfo
            icon={<STATS />}
            backgroundcolor={{ background: "#6f5bf1" }}
            title="Analytics"
            description="Receive information about members and messages that were delivered."
            headingsx={{ color: "#6f5bf1" }}
          />
          <PricingCardInfo
            icon={<VOLUME />}
            backgroundcolor={{ background: "#544fe5" }}
            title="Scheduled Announcements"
            description="Not only can you send announcements from our dashboard, they can be scheduled to your Discord channels and be mirrored across platforms (once links are created)."
            headingsx={{ color: "#544fe5" }}
          />
          <PricingCardInfo
            icon={<WHATSAPP />}
            backgroundcolor={{ background: "#50c865" }}
            title="WhatsApp Integration"
            description="Connect your Discord server with WhatsApp chats and groups. support all Telegram features except videos."
            headingsx={{ color: "#50c865" }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
