import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import Header from "../pages/header";
import Footer from "../pages/footer";
import Signup from "../assets/icons/signupimage.png";
import Signupsucces from "../assets/icons/signupsuccesimage.png";
import Waitlist from "../assets/icons/waitlistimage.png";
import verify from "../assets/icons/verifyimage.png";
import React from "react";

export default function GettingStarted() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <>
      <Header />
      <Stack
        //   id="hero"
        alignItems={desktop ? "center" : "center"}
        sx={{
          overflow: "hidden",
          //   background: "#383838",
          background: "#ffff",
          paddingBottom: "30px",
          letterSpacing: "0.75px",
        }}
        spacing={desktop ? "46px" : "12px"}
      >
        <Stack spacing={desktop ? "46px" : "12px"}>
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              color: "#7258e8",
              fontSize: desktop ? "48px" : "30px",
              textAlign: "center",
              paddingTop: "30px",
              width: "90%",
            }}
          >
            Getting Started with Announcely
          </Typography>
          <Divider />
        </Stack>
        <Stack
          paddingLeft={"20px"}
          paddingRight={"20px"}
          sx={{
            alignItems: "flex-start",
            justifyContent: desktop ? "center" : "flex-start",
            width: desktop ? "60%" : "90%",
            paddingBottom: "50px",
          }}
          spacing={"20px"}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            Welcome! In this article, I will guide you through the process of
            registering your Announcely account to join the waitlist. If you
            require additional assistance or find yourself encountering any
            issues, please don’t hesitate to reach out to us.
          </Typography>
          <Typography
            sx={{ color: "#333333", fontSize: desktop ? "28px" : "22px" }}
          >
            Step 1. Creating a new account
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            Please proceed to the following link: {""}
            <Typography
              color={"primary"}
              textAlign={"center"}
              component={"a"}
              sx={{ textDecoration: "none" }}
              href="https://my.announcely.app/#/signup"
            >
              https://my.announcely.app/signup{" "}
            </Typography>
            and provide your personal information along with your chosen
            password.
          </Typography>
          {/* <img src={Signup} alt="" /> */}
          <img
            src={Signup}
            alt=""
            style={{
              height: desktop ? "" : "70vh",
              width: desktop ? "" : "38vh",
              objectFit: "contain",

              // boxShadow: "0px 0px 10px 0px #0009",
            }}
          />
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            After clicking on the “Sign up” button, you should receive a
            confirmation message similar to the one displayed below:
          </Typography>
          <img
            src={Signupsucces}
            alt=""
            style={{
              height: desktop ? "" : "40vh",
              width: desktop ? "" : "40vh",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{
              borderLeft: "3px solid var(--Brand-600, #6165ec)",
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "16px",
                paddingLeft: "15px",
                // borderLeft: "3px solid var(--Brand-600, #6165ec)",
              }}
            >
              You have the option to register using your Google account,
              eliminating the need for a password. Simply click on the “Continue
              with Google” button, and this will establish a password-less
              account for you.
            </Typography>
          </Typography>

          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
            }}
          >
            Step 2. Verifying your email
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            Navigate to your email inbox, locate the most recent email sent by
            Announcely, and click on the “CLICK HERE TO VERIFY” button to
            confirm your email address.
          </Typography>
          <Typography>
            <img
              src={verify}
              alt=""
              style={{
                height: desktop ? "" : "40vh",
                width: desktop ? "" : "45vh",
                objectFit: "contain",

                // boxShadow: "0px 0px 10px 0px #0009",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            You will be automatically redirected to the login page, but if for
            any reason this doesn’t happen, you can manually return to {""}
            <Typography
              color={"primary"}
              textAlign={"center"}
              component={"a"}
              sx={{ textDecoration: "none" }}
              href="https://my.announcely.app/#/signup"
            >
              https://my.announcely.app/#/signup{" "}
            </Typography>{" "}
            and sign in to your newly created account.
          </Typography>
          <Typography
            sx={{
              color: "#515151",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "500",
            }}
          >
            Congratulations! Your account is now on the waitlist.
          </Typography>
          <img
            src={Waitlist}
            alt=""
            style={{
              height: desktop ? "" : "30vh",
              width: desktop ? "" : "40vh",
              objectFit: "contain",

              // boxShadow: "0px 0px 10px 0px #0009",
            }}
          />
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Why the waitlist?
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            Why the waitlist? The waitlist is in place because we are presently
            limiting the number of users who can access our application. This is
            to ensure a controlled and optimal user experience. To grant access
            to the application, we require manual account approval from our end.
            However, rest assured, this is a temporary measure and will be
            lifted as soon as we exit the beta phase of our service. We
            appreciate your understanding and patience during this period.
          </Typography>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
