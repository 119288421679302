import { ArrowRight } from "@mui/icons-material";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";
import { ARROWRIGHT } from "../assets/icons";

export default function QuestionCards(props: {
  heading: string;
  about: string;
}) {
  // props: {
  //   icon: ReactElement;
  //
  //
  //   style?: CSSProperties;
  // }
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      spacing={"20px"}
      sx={{
        width: desktop ? "470px" : "250px",
        // height: desktop ? "310px" : "200px",
        backgroundColor: "transparent",
        borderRadius: "8px",
        boxShadow:
          "6px  rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10)",
        // padding: desktop ? "10px 24px" : "10px 24px",
        alignItems: "start",
        lineHeight: "24px",
        // ":hover": {
        //   background: "transparent",
        //   backdropFilter: "blur(4px)",
        // },
      }}
    >
      <Typography
        sx={{
          color: "#fff",
          fontSize: desktop ? "23px" : "18px",
          fontStyle: "normal",
          fontWeight: desktop ? "600" : "400",
          textAlign: "start",
        }}
      >
        {/* Getting Started */}
        {props.heading}
      </Typography>
      <Typography
        sx={{
          textAlign: "start",
          color: "#b4b4b4b4",
          fontSize: desktop ? "18px" : "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {/* Join our waitlist seamlessly with our user-friendly signup process.Our
        detailed walkthrough will guide you through every step, making it quick
        and hassle-free. */}
        {props.about}
      </Typography>{" "}
    </Stack>
  );
}
