import { useEffect, useState } from "react";
import React from "react";

import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { SCR_DASHBOARD } from "../assets";
export default function Hero() {
  const [scrollOffset, setScrollOfset] = useState(window.scrollY);
  const desktop = useMediaQuery("(min-width: 700px)");
  const nav = useNavigate();
  function handleScroll() {
    setScrollOfset(Math.ceil(window.scrollY));
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      id="hero"
      alignItems={"center"}
      justifyContent={desktop ? "center" : "flex-start"}
      sx={{
        minHeight: desktop ? "100vh" : "auto",
        overflow: "visible",
        background: "#383838",
        paddingBottom: "30px",
        padding: desktop ? "70px 0px" : "10px",
      }}
      spacing={desktop ? "46px" : "40px"}
    >
      <Stack
        alignItems={"center"}
        justifyContent={desktop ? "center" : "flex-start"}
        spacing={desktop ? "24px" : "30px"}
      >
        <Stack>
          <motion.div
            initial={{ opacity: 0, filter: "blur(5px)" }}
            whileInView={{ opacity: 1, filter: "blur(0px)" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <Typography
              fontSize={desktop ? "58px" : "30px"}
              fontWeight={600}
              sx={{
                color: "#fff",
              }}
              textAlign={"center"}
            >
              Reach your community.
            </Typography>
          </motion.div>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <motion.div
              initial={{ opacity: 0, filter: "blur(5px)" }}
              whileInView={{ opacity: 1, filter: "blur(0px)" }}
              transition={{ duration: 1, delay: 1 }}
              viewport={{ once: true }}
            >
              <Typography
                fontSize={desktop ? "58px" : "30px"}
                fontWeight={600}
                sx={{
                  color: "#fff",
                }}
                textAlign={"center"}
              >
                Everywhere.
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, filter: "blur(5px)" }}
              whileInView={{ opacity: 1, filter: "blur(0px)" }}
              transition={{ duration: 1, delay: 2 }}
              viewport={{ once: true }}
            >
              <Typography
                fontSize={desktop ? "58px" : "30px"}
                fontWeight={600}
                sx={{
                  color: "#48F",
                }}
                textAlign={"center"}
              >
                Instantly.
              </Typography>
            </motion.div>
          </Stack>
        </Stack>
        <Typography
          fontSize={desktop ? "20px" : "16px"}
          fontWeight={400}
          style={{
            maxWidth: desktop ? "40%" : "80%",
            color: "rgba(255,255,255,0.8)",
            textAlign: "center",
          }}
          textAlign={"center"}
        >
          Announcely connects Discord with WhatsApp and Telegram, enabling
          community owners to reach their members seamlessly from one place.
        </Typography>
      </Stack>
      <motion.div
        whileInView={{ scale: 1 }}
        initial={{ scale: 0 }}
        transition={{ type: "spring", stiffness: 100, delay: 0 }}
      >
        <Button
          variant="contained"
          size="large"
          sx={{
            borderRadius: "30px",
            fontSize: desktop ? "18px" : "14",
            fontWeight: "600",
            pointerEvents: "all",
            border: "3px solid var(--Brand-600, #6165ec);",
            background: "transparent",
            color: "#6165ec",
            ":hover": {
              background: "#6165ec",
              color: "#fff",
            },
          }}
          onClick={() => {
            if (!window.document.getElementById("features")) nav("/#features");
            else {
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("features")?.offsetTop,
              });
            }
          }}
        >
          Learn More
        </Button>
      </motion.div>
      <Stack
        position={"relative"}
        alignItems={"center"}
        sx={{
          overflow: "visible",
          padding: "10px",
          width: "100%",
        }}
      >
        <motion.div
          style={{
            position: "absolute",
            transition: "all 1s",
            translateY: Math.max(-620, -scrollOffset),
          }}
          initial={{ translateY: 200 }}
          transition={{ duration: 1 }}
        >
          <img
            src={SCR_DASHBOARD}
            style={{
              objectFit: "contain",
              borderRadius: "12px",
              zIndex: 100,
            }}
          />
        </motion.div>
      </Stack>
    </Stack>
  );
}
