import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { ARROWRIGHT } from "../assets/icons";

export default function Join() {
  const desktop = useMediaQuery("(min-width: 700px)");
  function join() {
    var url = "https://discord.com/invite/emKaTp2chs";
    window.location.assign(url);
  }
  return (
    <Stack
      id="contact"
      direction={desktop ? "row" : "column"}
      spacing={desktop ? "" : "40px"}
      alignItems={"center"}
      justifyContent={"space-around"}
      padding={"130px 0px"}
      sx={{ background: "#1d2023" }}
    >
      <Typography
        fontSize={desktop ? "40px" : "28px"}
        fontWeight={400}
        sx={{
          maxWidth: desktop ? "55%" : "90%",
          color: "#fff",
        }}
        textAlign={"center"}
      >
        If you need any help, don't hesitate to reach out to us on Discord.
      </Typography>
      <Button
        sx={{
          width: desktop ? "150px" : "200px",
          padding: desktop ? "30px 20px" : "30px 10px",
          height: "40px",
          fontSize: "25px",
          borderRadius: "2px",
          border: "1px solid var(--Brand-600, #fff);",
          background: "transparent",
          color: "#fff",
          ":hover": {
            background: "#fff",
            color: "black",
          },
        }}
        onClick={join}
      >
        Join now
      </Button>
    </Stack>
  );
}
