import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import QuestionCards from "../components/QuestionCard";

export default function Question() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack id="faq" justifyContent={"center"} alignItems={"center"}>
      <Typography
        sx={{
          fontSize: "54px",
          color: "#fff",
          fontWeight: "600",
          padding: desktop ? "100px 0" : "20px",
          width: desktop ? "30%" : "auto",
          textAlign: "center",
        }}
      >
        Frequently asked questions
      </Typography>

      <Stack
        // direction={"row"}
        // columnGap={"50px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"50px"}
        columnGap={"50px"}
        flexWrap={"wrap"}

        // justifyContent={"space-between"}
      >
        <QuestionCards
          heading="Can I get a free trial?"
          about="Absolutely! We offer a free, 7-day trial before actually charging our customers for using our service. You can cancel any time during your trial."
        />
        <QuestionCards
          heading="What is your refund policy?"
          about="We do not offer a refund on purchases. However, customers can cancel their subscription before they are charged during their trial period."
        />
      </Stack>
      <Stack
        // direction={"row"}
        // columnGap={"50px"}
        paddingTop={"50px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"50px"}
        columnGap={"50px"}
        flexWrap={"wrap"}
        // justifyContent={"space-between"}
      >
        <QuestionCards
          heading="What support is included?'"
          about="We offer support through our own Discord server as well as through email."
        />
        <QuestionCards
          heading="Can I use my own bot tokens?"
          about="Yes! Both discord and telegram connections support custom bot tokens."
        />
      </Stack>
      <Stack
        // direction={"row"}
        // columnGap={"50px"}
        paddingTop={"50px"}
        paddingBottom={"50px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"50px"}
        columnGap={"50px"}
        flexWrap={"wrap"}

        // justifyContent={"space-between"}
      >
        <QuestionCards
          heading="The dashboard shows my bot as 'Offline'"
          about="This could be for many reasons... Such as inactive billing profile, your bot might be undergoing some updates, or it may be in a provisional state. Reach out to us on Discord if your bot is offline for longer periods."
        />
        <QuestionCards
          heading="The dashboard shows QR Code for WhatsApp, can it access my personal data?"
          about="It is highly recommended that you do not use your personal WhatsApp account with Announcely. Despite all the security measures in place, you should use a second WhatsApp account primarily for your business since connecting WhatsApp grants the application entire access to your WhatsApp account."
        />
      </Stack>
    </Stack>
  );
}
