import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import BotDevelopmentCard from "../components/DevelopmentBotCard";
import { COMMENT, INFINITYFUTURE, LINK } from "../assets/icons";
import Phone from "../assets/icons/phone.png";
import { SCR_DASHBOARD } from "../assets";
import React from "react";

export default function Features() {
  const [feature, setFeature] = useState("stripe");
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      id="features"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        background: "#1c1c1c",
        justifyContent: "center",
        paddingTop: desktop ? "40px" : "0px",
        paddingBottom: "30px",
      }}
    >
      <Stack
        direction={"column"}
        sx={{
          paddingTop: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "52px 52px",
          columnGap: "52px",
        }}
      >
        <Stack
          direction={desktop ? "row" : "column"}
          columnGap={"52px"}
          rowGap={desktop ? "52px" : "52px"}
        >
          <BotDevelopmentCard
            icon={<LINK />}
            heading="Link Chats Across Platforms"
            about="Easily create links across Discord channels to WhatsApp, and Telegram groups. Allowing chats to be forwarded with ease."
          />

          <BotDevelopmentCard
            icon={<INFINITYFUTURE />}
            heading="Unlimited Announcements"
            about="Announcely does not charge using a credit system therefore our users can enjoy the ability to send unlimited announcements across supported platforms."
          />
          <BotDevelopmentCard
            icon={<COMMENT />}
            heading="Scheduled Announcements"
            about="Send both normal and scheduled announcements to any Discord channel, whilst having it mirrored through the linked chats."
          />
        </Stack>
        <img
          src={Phone}
          style={{
            paddingTop: "40px",
            height: desktop ? "60vh" : "40vh",
            width: desktop ? "" : "45vh",
            objectFit: "contain",
            borderRadius: "12px",
          }}
        />
      </Stack>
    </Stack>
  );
}
