import React, { useEffect, useState } from "react";
import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import Header from "../pages/header";
import Signup from "../assets/icons/signupimage.png";
import Signupsucces from "../assets/icons/signupsuccesimage.png";
import Waitlist from "../assets/icons/waitlistimage.png";
import verify from "../assets/icons/verifyimage.png";
import Footer from "../pages/footer";
import Approved from "../assets/icons/approvedaccountsimage.png";
import Sub from "../assets/icons/Subscribeimage.png";
import trail from "../assets/icons/activatetrial.image.png";
import chosesub from "../Howto/images/chosesubscription.png";
import trailactive from "../Howto/images/trialactivesuccesimage.png";
import image10 from "../Howto/images/image-10.png";
import image11 from "../Howto/images/image-11.png";
import token from "../Howto/images/token.png";
import image12 from "../Howto/images/image-12.png";
import image13 from "../Howto/images/image-13.png";
import image14 from "../Howto/images/image-14.png";
import image15 from "../Howto/images/image-15.png";
import image16 from "../Howto/images/image-16.png";
import image17 from "../Howto/images/image-17.png";
import image18 from "../Howto/images/image-18.png";
import image19 from "../Howto/images/image-19.png";
import image20 from "../Howto/images/image-20.png";
import image21 from "../Howto/images/image21.png";
import image23 from "../Howto/images/image-23.png";
import image24 from "../Howto/images/image-24.png";
import image25 from "../Howto/images/image-25.png";
import image26 from "../Howto/images/image26.png";

import whatsappqr from "../Howto/images/whatsappqr.png";
import resttoken from "../Howto/images/resttoken.png";

export default function Onboarding() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <>
      <Header />
      <Stack
        //   id="hero"
        alignItems={"center"}
        sx={{
          minHeight: desktop ? "100vh" : undefined,
          overflow: "hidden",
          //   background: "#383838",
          background: "#ffff",
          paddingBottom: "30px",
          letterSpacing: "0.75px",
        }}
        spacing={desktop ? "46px" : "12px"}
      >
        {" "}
        {/* <img src={Signup} alt="" Step 0. Some Important Stuff/> */}
        <Stack
          spacing={desktop ? "46px" : "12px"}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              color: "#7258e8",
              fontSize: desktop ? "48px" : "30px",
              textAlign: "center",
              paddingTop: "30px",
              width: "90%",
            }}
          >
            Onboarding: Setting up Discord, Telegram, and WhatsApp
          </Typography>
          <Divider sx={{}} />
        </Stack>
        <Stack
          sx={{
            alignItems: "flex-start",
            justifyContent: desktop ? "center" : "flex-start",
            width: desktop ? "60%" : "80%",
            paddingBottom: "50px",
            paddingRight: desktop ? " 150px" : "2px",
            color: "#333333",
          }}
          spacing={"20px"}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            In this document, I will guide you through the onboarding flow of
            Announcely. The onboarding process is designed to help you
            effortlessly set up your Announcely dashboard and bot for use with
            your Discord server.
          </Typography>
          <img
            src={Approved}
            alt=""
            style={{
              height: desktop ? "auto" : "auto",
              width: desktop ? "60vh" : "35vh",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
            }}
          >
            Step 0. Some Important Stuff
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
            }}
          >
            1.1: Review our Privacy Policy and Terms of Service
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            I understand that reading through legal documents can be a daunting
            task, but it’s crucial. Our Privacy Policy and Terms of Service
            outline how our application collects and utilizes certain personal
            data to fulfill its intended functions. Additionally, please be
            aware that we leverage several third-party services, each of which
            has its own set of privacy policies and terms of service. You can
            find these documents on their respective pages, which are referenced
            within our Privacy Policy. It’s important to be informed about how
            your data is handled and used to ensure a transparent and secure
            experience.
          </Typography>
          {/* <img src={Signupsucces} alt="" /> */}
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            If you opt not to review these documents, please be aware that
            continuing to use our service implies your acceptance of the terms
            and conditions outlined and your understanding of our Privacy
            Policy. Your continued usage signifies your agreement with these
            important guidelines.
          </Typography>
          <Typography sx={{ color: "#333333", fontSize: "28px" }}>
            1.2: Safeguard Your Personal Information.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            Some of Announcely’s features necessitate extensive control over
            user accounts, especially when providing WhatsApp integration
            services. Announcely utilizes the WhatsApp Web API to connect with
            the user’s WhatsApp account, which grants the application full
            access to these accounts. To ensure maximum security, we strongly
            advise against using your personal accounts with Announcely for
            these purposes. Instead, we recommend creating a separate account
            dedicated exclusively for use with Announcely. This practice helps
            safeguard your personal information and maintains a clear separation
            between your personal and application-related activities.
          </Typography>
          <Typography>
            It’s important to note that Announcely and its partners cannot be
            held liable for any damages resulting from the mentioned scenarios.
            Therefore, when using these features, it is crucial to do so at your
            own risk.
          </Typography>
          <Typography
            sx={{ color: "#333333", fontSize: desktop ? "28px" : "22px" }}
          >
            Step 1. Referral code
          </Typography>
          <Typography>
            <img
              src={verify}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "" : "40vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography>
            If you possess a referral code provided by one of our affiliates or
            partners, please input it on the initial screen of the onboarding
            process. Referral codes are instrumental for unlocking discounts and
            special offers associated with different occasions and affiliate
            programs. This is the exclusive stage where you can enter your
            referral code. Once entered, this code will be automatically applied
            to your checkout sessions, and you will not have the opportunity to
            modify or re-enter it at a later time.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 2. Subscribe to Announcely
          </Typography>
          <img
            src={Sub}
            alt=""
            style={{
              height: desktop ? "auto" : "auto",
              width: desktop ? "70vh" : "40vh",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            In order to access the Announcely dashboard and its array of
            features, users must maintain an active subscription. We offer a
            free trial period, allowing you to thoroughly test the application
            before committing to a payment. During the trial, users will not
            incur any charges, and they have the flexibility to cancel at any
            time prior to incurring any charges. This provides users with a
            risk-free opportunity to explore Announcely’s capabilities.{" "}
          </Typography>
          <Typography>
            It’s essential to keep in mind that we do not have a refund policy
            in place. Subscriptions that have been canceled can still be
            utilized until their designated period comes to an end. Once a
            subscription is canceled, it will remain accessible until the end of
            the subscription period, and no refunds will be issued.
          </Typography>
          <Typography>
            Select your desired pricing plan, and then click on “Activate
            Trial.” This action will initiate a new Stripe checkout session,
            allowing you to subscribe to Announcely.
          </Typography>
          <Typography>
            <img
              src={trail}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "50vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography>
            Input your information and click “Subscribe” to complete the
            process.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            After subscribing, I am still seeing the price plans in onboarding
          </Typography>
          <Typography>
            <p>
              The reason you might still see the price plans in the onboarding
              process after subscribing is that Stripe can take a few seconds to
              send notifications to Announcely. In most cases, it can take up to
              30 seconds to process the subscription fully. If you encounter
              this delay, you can simply refresh the page, and you should then
              see the next screen.
            </p>
          </Typography>
          <Typography>
            <p>
              {" "}
              Once your subscription has been successfully processed, you will
              encounter the following screen.{" "}
            </p>
            <img
              src={trailactive}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              {" "}
              This indicates that your subscription has been successfully
              processed, and the service is currently provisioning your instance
              of the bot.{" "}
            </p>
          </Typography>
          <Typography>
            After your bot has been initiated, you will encounter this screen.
          </Typography>
          <Typography>
            <img
              src={chosesub}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography>
            Congratulations! Your bot is now operational and ready to go. It’s
            time to configure Discord, WhatsApp, and Telegram to make the most
            of your subscription.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3. Configuring Discord
          </Typography>
          <Typography>
            To configure Discord, you’ll need to set up a few key components and
            have a Discord server ready for your bot. Let’s delve into the
            process of connecting your Discord server with Announcely.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3.1: Creating the Discord Bot
          </Typography>
          <Typography>
            <p>
              If you don’t already have a bot, follow these steps to create a
              new one and enable the necessary settings for Announcely to
              operate seamlessly.
            </p>
            <p>
              If you already have a bot token, please make certain of the
              following:
            </p>
            <p>
              1. The bot token is not being utilized elsewhere, as it can result
              in conflicts with the bot application.
            </p>
            <p>
              2. The bot has all three intents enabled to process and read
              server messages, members, and more.
            </p>
            <p>
              3. The bot has sufficient permissions to send messages, read
              members, and access guild information.
            </p>
            Failure to correct any of the above settings for your bot could
            potentially lead to issues within the application later on.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3.1.1: Creating the bot application
          </Typography>
          <Typography>
            Navigate to the Discord Developers Portal and log in using your
            Discord account. After signing in, click on the “New Application”
            button located in the top-right corner.
          </Typography>
          <img
            src={image10}
            alt=""
            style={{
              height: desktop ? "" : "auto",
              width: desktop ? "" : "30vh",
              objectFit: "contain",
            }}
          />
          <Typography>
            Provide a name for your new bot application, and then click
            “Create.”
          </Typography>
          <img
            src={image11}
            alt=""
            style={{
              height: desktop ? "" : "auto",
              width: desktop ? "" : "35vh",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3.1.2: Getting Client ID and Bot Token
          </Typography>
          <Typography>
            Next, in the “General Information” tab, copy the Application ID.
            This unique identifier is your Client ID, and you will need to enter
            it in the Discord Configuration screen during the Onboarding
            process.
          </Typography>
          <Typography>
            <img
              src={token}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Now, proceed to the “Bot” section found in the sidebar and click
              on the “Reset Token” button. This action will generate a new bot
              token specifically for this bot application.
            </p>
            <img
              src={resttoken}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              It’s crucial to ensure you copy your bot token at this point, as
              this is the only instance where you will be able to view it.
            </p>
            <img
              src={image12}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3.1.3: Enable Bot Intents
          </Typography>
          <Typography>
            <p>
              This is a crucial step in configuring the Discord bot. In the same
              “Bot” section, scroll down to “Privileged Gateway Intents” and
              ensure that the following are enabled:
            </p>
            <img
              src={image13}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Enabling these settings will grant your bot the ability to receive
              presence, member, and message content information, which is
              essential for its proper functionality.
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3.2: Adding the bot to your Discord server
          </Typography>
          <Typography>
            Now that you’ve successfully created and configured the new bot,
            it’s time to add the bot to your own Discord server. In the “OAuth2”
            section of the sidebar, access the “URL Generator” and select the
            following scopes:
            <img
              src={image14}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Next, choose ‘Administrator’ in the bot scopes to grant your bot
              full access to the Discord server. This will ensure that your bot
              continues to function seamlessly even if new updates are
              introduced to our Discord features. Alternatively, you can also
              select the following permissions for the bot.
            </p>
          </Typography>
          <Typography>
            We strongly recommend providing your bot with administrative
            permissions as this ensures your bot can continue functioning
            seamlessly with future updates.
          </Typography>
          <Typography>
            <img
              src={image15}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />{" "}
            <p>
              Once you’ve selected the necessary scopes, a new URL will be
              generated at the bottom. Copy this URL and paste it in your web
              browser or in Discord, then click on it to initiate the bot’s
              addition to your server.
            </p>
          </Typography>{" "}
          <Typography>
            <img
              src={image16}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Open the link in your web browser or in Discord. Then, choose the
              server where you wish to add the bot and click on “Continue.”
            </p>
          </Typography>
          <Typography>
            {" "}
            <img
              src={image17}
              alt=""
              style={{
                height: desktop ? "70vh" : "auto",
                width: desktop ? "" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              {" "}
              Proceed by authorizing the bot’s permissions; click on the
              “Authorize” button, as demonstrated below.
            </p>
            <img
              src={image18}
              alt=""
              style={{
                height: desktop ? "70vh" : "auto",
                width: desktop ? "" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              {" "}
              Your bot should now be successfully added to your Discord server!
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3.3: Connecting the bot with Announcely{" "}
          </Typography>
          <Typography>
            <p>
              {" "}
              Return to Announcely, navigate to the bot configuration screen,
              select “Discord,” and input the bot token and client ID obtained
              from the previous steps. Finally, click on “Save.”
            </p>
            <img
              src={image19}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "50vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              {" "}
              If the bot token and client ID have been correctly entered, you
              will receive a success message and see the server selection menu
              appear below.{" "}
            </p>
            <img
              src={image20}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "50vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              {" "}
              In the drop-down menu, select your server, and return to the main
              screen to continue configuring other platforms.{" "}
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 4. Connecting WhatsApp
          </Typography>
          <Typography>
            <img
              src={whatsappqr}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "50vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              This part of the configuration flow is remarkably straightforward.
              Since Announcely utilizes WhatsApp Web, all you need to do is scan
              the QR Code with your phone using an active WhatsApp app.
            </p>
            <p>
              Access the options menu in your WhatsApp mobile app, then open
              “Linked Devices.” From there, select “Add Device” and proceed to
              scan the QR Code displayed on the screen.
            </p>
            <Typography>
              Note! Please note that you may not see immediate progress updates
              regarding WhatsApp login. After scanning the QR code, be patient,
              as it may take a few moments for your WhatsApp account to load on
              the bot.
            </Typography>
            <p>
              Once your WhatsApp account has been successfully connected, you
              will see its details displayed as shown below:
            </p>
            <img
              src={image21}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "40vh" : "30vh",
                objectFit: "contain",
              }}
            />
            <p>
              With your WhatsApp account successfully configured, you can now
              return to the main screen to continue configuring the other bots.
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 5. Configuring Telegram
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 5.1: Connecting a bot account
          </Typography>
          <Typography>
            To establish a connection between Announcely and a bot account, you
            will need a bot token. Here’s a step-by-step guide on how to obtain
            a bot token:
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 5.1.1: Getting a bot token
          </Typography>
          <Typography>
            <p>
              Open your Telegram account and search for @BotFather – an official
              Telegram bot that enables the creation of bot tokens for Telegram.
            </p>
            <img
              src={image23}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "55vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Start a chat with the @BotFather and send the command /start or
              click on the “Start” button. You will then be presented with a
              list of available commands that you can use with this bot. To
              create a new bot, type /newbot and follow the on-screen
              instructions provided by @BotFather.
            </p>
            <img
              src={image24}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "60vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              After creating your new bot, you will receive a token. Click on
              the token to copy it, or manually select and copy it. Make sure to
              save this token in a secure place as you will need it in the next
              step.
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 5.1.2: Connecting with the telegram bot{" "}
          </Typography>
          <Typography>
            <p>
              {" "}
              Once you have obtained the bot token, return to the Announcely
              configuration screen and click on the Telegram button.
            </p>
            <img
              src={image25}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "55vh" : "30vh",
                objectFit: "contain",
              }}
            />
            <p>
              {" "}
              In the configuration screen, select the “Bot” option and input the
              bot token you acquired in the previous step, as shown below:
            </p>
            <img
              src={image26}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "50vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Click on “Save,” and if the token is valid, your bot will be
              successfully connected with Announcely.
            </p>
            <p>
              Congratulations! Your Telegram bot is now successfully connected
              with Announcely!
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 5.1.3: Telegram Bot Permissions{" "}
          </Typography>
          <Typography>
            Please read the following if your telegram bot is not able to detect
            chats or process messages.
          </Typography>
          <Typography>
            To be able to process (and send) messages and chat updates in
            Telegram group chats and channels, you must add the bot as an
            administrator.
            <p>
              It is important for the bot to have administrator or message
              access, otherwise the bot may not be able to receive message
              events or detect chats at all.
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 6. Finish Onboarding
          </Typography>
          <Typography>
            That’s it! Once you have one or two platforms connected, you can
            conclude the onboarding flow and begin using the Announcely
            dashboard.
          </Typography>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
