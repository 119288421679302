import { useEffect, useState } from "react";
import React from "react";

import { Stack, useMediaQuery } from "@mui/material";
import LinkButton from "../components/LinkButton";
import { useNavigate } from "react-router";
import { LOGO_NAVBAR } from "../assets";

export default function Header() {
  const [stick, setStick] = useState(false);
  const nav = useNavigate();
  const desktop = useMediaQuery("(min-width: 700px)");
  function Contact() {
    var url = "./contactus";
    window.location.assign(url);
  }
  function handleScroll() {
    setStick(Math.ceil(window.scrollY + 10) > 400);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      rowGap={"12px"}
      sx={{
        zIndex: 100,
        pt: stick ? "8px" : "12px",
        pb: stick ? "8px" : "12px",
        pl: stick ? "px" : "8px",
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        position: stick ? "sticky" : "unset",
        background: stick ? "#38383833" : "#383838",
        backdropFilter: "blur(4px)",
      }}
    >
      <Stack
        id="header"
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
      >
        <img
          src={LOGO_NAVBAR}
          alt="Announcely Logo"
          style={{ height: "53px", objectFit: "contain" }}
        />

        {desktop && (
          <nav
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              rowGap: "0",
              columnGap: 0,
            }}
          >
            <LinkButton
              observable="hero"
              label="Home"
              onClick={() => {
                if (!window.document.getElementById("hero")) nav("/");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("hero")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Features"
              observable="features"
              onClick={() => {
                if (!window.document.getElementById("features"))
                  nav("/#features");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("features")?.offsetTop,
                  });
                }
              }}
            />

            <LinkButton
              observable="Pricing"
              label="Pricing"
              onClick={() => {
                if (!window.document.getElementById("Pricing"))
                  nav("/#Pricing");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Pricing")?.offsetTop,
                  });
                }
              }}
            />

            <LinkButton
              observable="faq"
              label="Faq"
              onClick={() => {
                if (!window.document.getElementById("faq")) nav("/#faq");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("faq")?.offsetTop,
                  });
                }
              }}
            />

            <LinkButton
              observable="demo"
              label="Tutorials"
              onClick={() => {
                if (!window.document.getElementById("demo")) nav("/#demo");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("demo")?.offsetTop,
                  });
                }
              }}
            />

            <LinkButton
              observable="contact"
              label="Contact"
              onClick={() => {
                if (!window.document.getElementById("contact"))
                  nav("/#contact");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("contact")?.offsetTop,
                  });
                }
              }}
            />
          </nav>
        )}
      </Stack>
      {!desktop && (
        <nav
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <LinkButton
            observable="hero"
            label="Home"
            onClick={() => {
              if (!window.document.getElementById("hero")) nav("/");
              else {
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("hero")?.offsetTop,
                });
              }
            }}
          />
          <LinkButton
            label="Features"
            observable="features"
            onClick={() => {
              if (!window.document.getElementById("features"))
                nav("/#features");
              else {
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("features")?.offsetTop,
                });
              }
            }}
          />

          <LinkButton
            observable="Pricing"
            label="Pricing"
            onClick={() => {
              if (!window.document.getElementById("Pricing")) nav("/#Pricing");
              else {
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("Pricing")?.offsetTop,
                });
              }
            }}
          />

          <LinkButton
            observable="faq"
            label="Faq"
            onClick={() => {
              if (!window.document.getElementById("faq")) nav("/#faq");
              else {
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("faq")?.offsetTop,
                });
              }
            }}
          />

          <LinkButton
            observable="demo"
            label="Tutorials"
            onClick={() => {
              if (!window.document.getElementById("demo")) nav("/#demo");
              else {
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("demo")?.offsetTop,
                });
              }
            }}
          />

          <LinkButton
            observable="contact"
            label="Contact"
            onClick={() => {
              if (!window.document.getElementById("contact")) nav("/#contact");
              else {
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("contact")?.offsetTop,
                });
              }
            }}
          />
        </nav>
      )}
    </Stack>
  );
}
