import React from "react";
import {
  Divider,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "../pages/header";
import Signup from "../assets/icons/signupimage.png";
import Signupsucces from "../assets/icons/signupsuccesimage.png";
import Waitlist from "../assets/icons/waitlistimage.png";
import verify from "../assets/icons/verifyimage.png";
import Footer from "../pages/footer";
import YouTubePlayer from "react-player/youtube";
import image1 from "./images/dc1.png";
import image2 from "./images/dc2.png";
import image3 from "./images/d3.png";
import image4 from "./images/dc4.png";

export default function LinkingDiscord() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <>
      <Header />
      <Stack
        //   id="hero"
        alignItems={"center"}
        //   justifyContent={desktop ? "center" : "flex-start"}
        sx={{
          minHeight: desktop ? "100vh" : undefined,
          overflow: "hidden",
          //   background: "#383838",
          background: "#ffff",
          paddingBottom: "30px",
        }}
        spacing={desktop ? "46px" : "12px"}
      >
        <Stack
          spacing={desktop ? "46px" : "12px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              color: "#7258e8",
              fontSize: desktop ? "48px" : "30px",
              textAlign: "center",
              paddingTop: "30px",
              width: desktop ? "60%" : "90%",
            }}
          >
            Linking Discord Channels with Telegram and WhatsApp{" "}
          </Typography>
          <Divider sx={{}} />
        </Stack>
        <Stack
          sx={{
            justifyContent: "center",
            width: desktop ? "60%" : "90%",
            paddingBottom: "50px",
            color: "#333333",
            letterSpacing: "0.75px",
          }}
          spacing={"20px"}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
              width: "100%",
            }}
          >
            In this article, I will guide you through the process of linking a
            Discord channel to WhatsApp and/or Telegram using Announcely.{" "}
            <p>
              Before we begin, please ensure that your Announcely bot is set up
              and connected with Discord, Telegram, and/or WhatsApp.
            </p>
          </Typography>
          <Typography>
            <Paper
              style={{
                borderRadius: "8px",
                //   overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                width: desktop ? undefined : "80%",
              }}
            >
              <YouTubePlayer
                url={"https://youtu.be/HazsNvN-cNk"}
                width={desktop ? undefined : "auto"}
                height={desktop ? undefined : "auto"}
              />
            </Paper>
          </Typography>
          <Typography>Video tutorial on how to link chats.</Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "500",
            }}
          >
            What is a Linked Chat?{" "}
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            A linked chat establishes a connection between a specified Discord
            text-based channel and either WhatsApp or a Telegram chat. Once a
            link is created for a Discord channel, every message sent to that
            channel will be forwarded to the connected linked chat on WhatsApp
            or Telegram. Additionally, even edited messages will be reflected
            across both platforms. It’s important to note that platforms may
            have limits on the duration for which you can edit messages.{" "}
          </Typography>
          {/* <img src={Signup} alt="" /> */}

          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "500",
            }}
          >
            Use Case{" "}
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            For demonstration purposes, we will use our own Discord server,
            Telegram bot, and WhatsApp Group chat to illustrate how to link them
            with one of our Discord channels. This process will enable us to
            seamlessly send messages from Discord to the other platforms, such
            as an updates channel.
          </Typography>

          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 1. Log in to your Announcely dashboard{" "}
          </Typography>
          <Typography>
            <img
              src={image1}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              {/* notelink here */}
              Navigate to the{" "}
              <Typography
                color={"primary"}
                textAlign={"center"}
                component={"a"}
                sx={{
                  ":hover": {
                    color: "#33333",
                  },
                  textDecoration: "none",
                  borderBottom: "1px solid var(--Brand-600, #6165ec)",
                }}
                href="https://my.announcely.app"
              >
                Announcely Portal
              </Typography>{" "}
              and log in with your account. Then, access the ‘Linked Chats’
              section and click on the ‘Create Link’ button. This will prompt
              the following dialog:
            </p>
            <img
              src={image2}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Simply choose your Discord server and the specific channel you
              wish to create the link for.
            </p>
          </Typography>
          <Typography>
            Next, select the platform to which you want to link the channel.
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 2. Linking Channel with Telegram and WhatsApp{" "}
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "500",
            }}
          >
            Linking WhatsApp Chat{" "}
          </Typography>
          <Typography
            sx={{
              borderLeft: "6px solid var(--Brand-600, #eeeeee)",
            }}
          >
            <Typography sx={{ paddingLeft: "20px" }}>
              {/* border */}
              This functionality is exclusively available for users with a
              Premium subscription.
            </Typography>
          </Typography>
          <Typography>
            <p>
              {" "}
              Choose “WhatsApp” in the target platform option, and you should
              see a drop-down menu displaying all the WhatsApp chats linked to
              your connected WhatsApp account.
            </p>
            <img
              src={image3}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Choose the chat or group chat that you’d like to link, and then
              click on “Create.”
            </p>
            <p>
              Congratulations! Your Discord channel is now successfully linked
              with the chosen WhatsApp chat.
            </p>
            <p>
              Now, every time a new message is received in the “new-members”
              channel, it will be automatically forwarded to our WhatsApp chat.
              Pretty cool, isn’t it? 🙂
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "500",
            }}
          >
            Linking Telegram Chat
          </Typography>
          <Typography
            sx={{
              borderLeft: "6px solid var(--Brand-600, #eeeeee)",
            }}
          >
            <Typography sx={{ paddingLeft: "20px" }}>
              This functionality is available for both Telegram and Premium
              subscriptions.
            </Typography>
          </Typography>
          <Typography>
            <p>
              To link your Discord channel with Telegram, the process is just as
              straightforward. Select “Telegram” in the platform options, choose
              your desired Telegram chat, and then click on “Create.” It’s that
              simple!{" "}
            </p>
            <p>
              It’s important to be aware that bots do not possess chat history.
              If you’ve recently added your Telegram bot to a channel or group,
              it may not appear in the chat drop-down menu until you send a
              message to that chat.
            </p>
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: desktop ? "28px" : "22px",
              fontWeight: "600",
            }}
          >
            Step 3. Deleting a Link
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "16px",
            }}
          >
            To remove a linked channel (or a link) from your Discord channels to
            other platforms, follow these steps:
          </Typography>
          <Typography>
            Navigate to the ‘Linked Chat’ section and identify the link that you
            wish to delete.
          </Typography>
          <Typography>
            <p>
              Click on the link, and a new dialog will appear, as shown below:
            </p>
            <img
              src={image4}
              alt=""
              style={{
                height: desktop ? "" : "auto",
                width: desktop ? "70vh" : "35vh",
                objectFit: "contain",
              }}
            />
            <p>
              Click on “Delete” to permanently remove the link. Please be aware
              that this action is irreversible.
            </p>
          </Typography>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
