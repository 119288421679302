import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import GetStartImage from "../assets/icons/getstart.png";
import { useNavigate } from "react-router";
export default function GetStart() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const nav = useNavigate();

  function getstart() {
    if (!window.document.getElementById("contact")) nav("/#contact");
    else {
      window.scrollTo({
        behavior: "smooth",
        top: window.document.getElementById("contact")?.offsetTop,
      });
    }
  }
  return (
    <Stack sx={{ background: "#2a2a2a" }}>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        spacing={"50px"}
        sx={{ padding: "50px" }}
      >
        <Typography
          sx={{
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            fontSize: "32px",
            fontWeight: 800,
            lineHeight: "30px",
            textAlign: "center",
          }}
        >
          Ready to get started?
        </Typography>
        <Button
          // size={desktop ? "large" : "small"}
          variant="text"
          sx={{
            width: desktop ? "180px" : "100px",
            borderRadius: "30px",
            color: "#fff",
            border: "2px solid rgba(255, 255, 255, 0.15)",
            fontSize: "22px",
            fontWeight: "500",
            background: "#6165ec",
            ":hover": {
              background: "#5c5c5c",
            },
          }}
          onClick={getstart}
        >
          Contact Us
        </Button>
        <Typography>
          <img
            src={GetStartImage}
            alt=""
            style={{
              width: desktop ? "90vh" : "40vh",
              objectFit: "contain",
              borderRadius: "12px",

              // boxShadow: "0px 0px 10px 0px #0009",
            }}
          />
        </Typography>
      </Stack>
    </Stack>
  );
}
