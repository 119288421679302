import { Alert, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import DualHeading from "../components/DualHeading";
import PFMInput from "../components/PFMInput";
import PFMCheckbox from "../components/PFMCheckbox";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { AppConfig } from "../config";
import { SendWebhookMessage } from "../core/helper";
export default function ContactUs() {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const desktop = useMediaQuery("screen and (min-width: 710px)");

  async function sendForm() {
    try {
      setSubmitting(true);
      await SendWebhookMessage(
        AppConfig.discord.webhook,
        `Contact Form Submission from ${name}`,
        `${msg}`,
        0x0f0,
        [
          {
            name: "Full name",
            value: name,
            inline: true,
          },
          {
            name: "Email",
            value: email,
            inline: true,
          },
          {
            name: "Subject",
            value: subject,
          },
        ]
      );
      enqueueSnackbar("Your message has been sent. ", { variant: "success" });
      setSubmitted(true);
    } catch (err: any) {
      enqueueSnackbar("Error submitting form. Please try again.", {
        variant: "error",
      });
    }
    setSubmitting(false);
  }

  return (
    <Stack
      id="contact"
      alignItems={"center"}
      sx={{
        bgcolor: "#1d2023",
        px: "10%",
        py: "48px",
      }}
      spacing={"32px"}
    >
      <Typography sx={{ color: "#fff", fontSize: "40px", fontWeight: "600" }}>
        Contact Us
      </Typography>
      <Stack spacing={"4px"} alignItems={"center"}>
        <Typography fontSize={desktop ? 20 : 18}>
          Have more questions?
        </Typography>
        <DualHeading
          sx={{ fontSize: desktop ? "28px" : "22px" }}
          h1="Contact"
          h2="Us"
          h2sx={{ color: "#48F" }}
        />
        <Typography fontSize={desktop ? 16 : 12} textAlign={"center"}>
          You can also reach us anytime via{" "}
          <Typography
            fontSize={desktop ? 16 : 12}
            textAlign={"center"}
            display={"inline"}
            color={"primary"}
            component={"a"}
            href="mailto:contact@announcely.app"
            sx={{ textDecoration: "none" }}
          >
            contact@announcely.app
          </Typography>
        </Typography>
      </Stack>
      <Stack sx={{ width: "100%" }} alignItems={"center"}>
        <Stack
          flex={1}
          sx={{
            maxWidth: desktop ? "600px" : "100%",
            bgcolor: "#FFF1",
            p: "32px",
            borderRadius: "12px",
          }}
          spacing={"8px"}
        >
          <PFMInput
            disabled={submitted || submitting}
            label="Full name"
            placeholder="Enter your full name..."
            text={name}
            onUpdate={(t) => setName(t)}
          />
          <PFMInput
            disabled={submitted || submitting}
            label="Email"
            placeholder="Enter your email address..."
            text={email}
            onUpdate={(t) => setEmail(t)}
          />
          <PFMInput
            disabled={submitted || submitting}
            label="Subject"
            placeholder="What is the message about?"
            text={subject}
            onUpdate={(t) => setSubject(t)}
          />
          <PFMInput
            disabled={submitted || submitting}
            label="Message"
            multiline
            text={msg}
            onUpdate={(t) => setMsg(t)}
            rows={8}
            placeholder="Write your message."
          />
          <PFMCheckbox
            typographySx={{ fontSize: desktop ? 14 : 10 }}
            value={agreement}
            onUpdate={(t) => setAgreement(t)}
            label="By submitting this form, you agree to Announcely's friendly privacy policy."
          />
          <SnackbarProvider autoHideDuration={2000}>
            {!submitted && (
              <Button
                variant="contained"
                disabled={submitting || !agreement}
                onClick={sendForm}
              >
                Submit
              </Button>
            )}
          </SnackbarProvider>
          {submitted && <Alert>Your message has been sent.</Alert>}
        </Stack>
      </Stack>
    </Stack>
  );
}
